import React from "react";
import { connect } from "react-redux";
import { FeatureFlag } from "./components/featureFlag/FeatureFlag";
import { AuthState } from "./reducers/auth";
import { CompaniesState } from "./reducers/companies";
import { PublicDataState } from "./reducers/public";
import { getAttributes, getCompanyFromState, isTreatmentOn } from "./services/app/split";
import { GlobalState } from "./types";

interface OwnProps {
    ignoreFeatureFlag?: boolean;
}

interface StateProps {
    auth: AuthState;
    companies: CompaniesState;
    publicData: PublicDataState;
}

type Props = OwnProps & StateProps;

interface State {
    hubspotScriptSource: string;
}

class HubSpotLoader extends React.Component<Props, State> {
    scriptTagId = "hs-script-loader";

    constructor(props: Props) {
        super(props);
        this.state = {
            hubspotScriptSource: "https://js.hs-scripts.com/2427383.js"
        };
    }

    async componentDidUpdate() {
        // const partialRefundsEnabled = await isTreatmentOn(FeatureFlag.PartialRefunds, attributes);
        const { auth, companies, publicData } = this.props;
        const isUserLoggedIn = Boolean(auth?.me?.id);
        const company = getCompanyFromState(companies, publicData, auth?.me);
        const attributes = getAttributes(auth?.me, company);
        const isCsChatWidgetSwitchEnabled = await isTreatmentOn(FeatureFlag.CsChatWidgetSwitch, attributes);
        const isScriptAlreadyExists = Boolean(document.querySelector("#" + this.scriptTagId));

        if (!isUserLoggedIn) {
            return;
        }

        if (isScriptAlreadyExists) {
            return;
        }

        if (!isCsChatWidgetSwitchEnabled) {
            return;
        }

        const scriptNode = document.createElement("script");
        scriptNode.setAttribute("src", this.state.hubspotScriptSource);
        scriptNode.setAttribute("id", this.scriptTagId);
        document.head.appendChild(scriptNode);
    }

    render(): React.ReactNode {
        return <></>;
    }
}

const propsFromState = ({ auth, companies, publicData }: GlobalState) => ({ auth, companies, publicData });

export default connect(propsFromState)(HubSpotLoader);
